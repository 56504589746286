import React, { MouseEvent, ReactNode, useEffect, useState } from 'react'

import {
  AlertCircleIcon,
  Heading,
  Paragraph,
  Tab,
  Tabs
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'

type LinkProps = {
  children: ReactNode
  to: string
}

const Link = ({ children, to, ...rest }: LinkProps) => {
  const onClick = (event: MouseEvent) => {
    event.preventDefault()
    window.location.hash = to
  }

  return (
    <a
      {...rest}
      href={to}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const [hash, setHash] = useState('')

  useEffect(() => {
    const hashChange = () => setHash(window.location.hash)
    window.addEventListener('hashchange', hashChange, false)
    return () => window.removeEventListener('hashchange', hashChange, false)
  }, [])

  return (
    <Content
      heading="Tabs"
      components={[
        { component: Tabs },
        { component: Tab, restElement: 'component' }
      ]}
    >
      <Section>
        <Paragraph>
          Käytetään jaottelemaan monimutkaisia sisältökokonaisuuksia pienemmiksi
          asiakokonaisuuksiksi.
        </Paragraph>
      </Section>
      <Section title="Esimerkkejä">
        <Playground>
          <>
            <Heading
              level={1}
              id="heading-id"
            >
              Sivun pääotsikko (h1) joka kuvaa myös täbien merkityksen
            </Heading>
            <Tabs ariaLabelledby="heading-id">
              <Tab
                active={hash === ''}
                as={Link}
                to=""
              >
                Lorem ipsum
              </Tab>
              <Tab
                active={hash === '#page-2'}
                as={Link}
                to="#page-2"
              >
                Dolor sit amet
              </Tab>
              <Tab
                active={hash === '#page-3'}
                as={Link}
                color="danger"
                iconRight={<AlertCircleIcon />}
                to="#page-3"
              >
                Consectetuer adipiscing
              </Tab>
              <Tab disabled>Sed posuere</Tab>
            </Tabs>
          </>
        </Playground>
        <Playground>
          <>
            <Heading
              level={1}
              id="heading-id"
            >
              Lorem ipsum
            </Heading>

            <Tabs ariaLabelledby="heading-id">
              <Tab
                active={hash === ''}
                as={Link}
                to=""
              >
                Sed posuere interdum sem
              </Tab>
              <Tab
                active={hash === '#page-2'}
                as={Link}
                to="#page-2"
              >
                Quisque ligula eros ullamcorper
              </Tab>
              <Tab
                active={hash === '#page-3'}
                as={Link}
                color="danger"
                iconRight={<AlertCircleIcon />}
                to="#page-3"
              >
                Mauris varius diam vitae arcu
              </Tab>
              <Tab
                active={hash === '#page-4'}
                as={Link}
                to="#page-4"
              >
                Sed arcu lectus auctor vitae
              </Tab>
              <Tab
                active={hash === '#page-5'}
                as={Link}
                to="#page-5"
              >
                Consectetuer et venenatis eget velit
              </Tab>
              <Tab
                active={hash === '#page-6'}
                as={Link}
                to="#page-6"
              >
                Donec ultricies nisl ut felis
              </Tab>
              <Tab
                active={hash === '#page-7'}
                as={Link}
                to="#page-7"
              >
                Aliquam erat volutpat
              </Tab>
              <Tab
                active={hash === '#page-8'}
                as={Link}
                to="#page-8"
              >
                Suspendisse facilisis nulla
              </Tab>
              <Tab disabled>Quisque purus lectus</Tab>
            </Tabs>
          </>
        </Playground>

        <Playground format="html">
          <>
            <h1 id="heading-id">Lorem ipsum</h1>
            <nav
              aria-labelledby="heading-id"
              className="tabs"
            >
              <ul className="tabs-list">
                <li className="tab">
                  <a href="#lorem-ipsum">
                    <span>Lorem ipsum</span>
                  </a>
                </li>
                <li className="tab active">
                  <a
                    href="#dolor-sit-amet"
                    aria-current="page"
                  >
                    <span>Dolor sit amet</span>
                  </a>
                </li>
                <li className="tab">
                  <a href="#consectetuer">
                    <span>Consectetuer</span>
                  </a>
                </li>
              </ul>
            </nav>
          </>
        </Playground>

        <SectionHeading>Toissijaiset välilehdet</SectionHeading>
        <Paragraph>
          Kun sivulla on jo ensisijaiset välilehdet, on mahdollista käyttää
          visuaalisesti erovia toissijaisia välilehtiä.
        </Paragraph>
        <Playground>
          <>
            <Heading
              level={2}
              id="heading-id"
            >
              Heading
            </Heading>
            <Tabs
              ariaLabelledby="heading-id"
              variant="secondary"
            >
              <Tab
                active={hash === ''}
                as={Link}
                to=""
              >
                Lorem ipsum
              </Tab>
              <Tab
                active={hash === '#page-2'}
                as={Link}
                to="#page-2"
              >
                Dolor sit amet
              </Tab>
              <Tab
                active={hash === '#page-3'}
                as={Link}
                color="danger"
                iconRight={<AlertCircleIcon />}
                to="#page-3"
              >
                Consectetuer adipiscing
              </Tab>
              <Tab disabled>Sed posuere</Tab>
            </Tabs>
          </>
        </Playground>
        <Playground>
          <>
            <Heading
              level={2}
              id="heading-id"
            >
              Heading
            </Heading>
            <Tabs
              ariaLabelledby="heading-id"
              variant="secondary"
            >
              <Tab
                active={hash === ''}
                as={Link}
                to=""
              >
                Sed posuere interdum sem
              </Tab>
              <Tab
                active={hash === '#page-2'}
                as={Link}
                to="#page-2"
              >
                Quisque ligula eros ullamcorper
              </Tab>
              <Tab
                active={hash === '#page-3'}
                as={Link}
                color="danger"
                iconRight={<AlertCircleIcon />}
                to="#page-3"
              >
                Mauris varius diam vitae arcu
              </Tab>
              <Tab
                active={hash === '#page-4'}
                as={Link}
                to="#page-4"
              >
                Sed arcu lectus auctor vitae
              </Tab>
              <Tab
                active={hash === '#page-5'}
                as={Link}
                to="#page-5"
              >
                Consectetuer et venenatis eget velit
              </Tab>
              <Tab
                active={hash === '#page-6'}
                as={Link}
                to="#page-6"
              >
                Donec ultricies nisl ut felis
              </Tab>
              <Tab
                active={hash === '#page-7'}
                as={Link}
                to="#page-7"
              >
                Aliquam erat volutpat
              </Tab>
              <Tab
                active={hash === '#page-8'}
                as={Link}
                to="#page-8"
              >
                Suspendisse facilisis nulla
              </Tab>
              <Tab disabled>Quisque purus lectus</Tab>
            </Tabs>
          </>
        </Playground>
        <SectionHeading>Huomio-merkki</SectionHeading>
        <Paragraph>
          Jos välilehdellä on käyttäjälle jotain huomioitavaa, välilehdellä voi
          näyttää huomio-merkin.
        </Paragraph>
        <Playground>
          <>
            <Heading
              level={2}
              id="heading-id"
            >
              Heading
            </Heading>
            <Tabs ariaLabelledby="heading-id">
              <Tab
                active={hash === ''}
                as={Link}
                badge
                to=""
              >
                Lorem ipsum
              </Tab>
              <Tab
                active={hash === '#page-2'}
                as={Link}
                to="#page-2"
              >
                Dolor sit amet
              </Tab>
              <Tab
                active={hash === '#page-3'}
                as={Link}
                color="danger"
                iconRight={<AlertCircleIcon />}
                to="#page-3"
              >
                Consectetuer adipiscing
              </Tab>
              <Tab disabled>Sed posuere</Tab>
            </Tabs>
          </>
        </Playground>
        <Playground>
          <>
            <Heading
              level={2}
              id="heading-id"
            >
              Heading
            </Heading>
            <Tabs
              ariaLabelledby="heading-id"
              variant="secondary"
            >
              <Tab
                active={hash === ''}
                as={Link}
                badge
                to=""
              >
                Lorem ipsum
              </Tab>
              <Tab
                active={hash === '#page-2'}
                as={Link}
                to="#page-2"
              >
                Dolor sit amet
              </Tab>
              <Tab
                active={hash === '#page-3'}
                as={Link}
                color="danger"
                iconRight={<AlertCircleIcon />}
                to="#page-3"
              >
                Consectetuer adipiscing
              </Tab>
              <Tab disabled>Sed posuere</Tab>
            </Tabs>
          </>
        </Playground>
      </Section>
      <Section title="Saavutettavuus">
        <Paragraph>
          <Code>Tabs</Code> tulee linkittää <Code>ariaLabelledby</Code>-propilla
          sen yläpuolella olevaan otsikkoon tai muuhun yhdistävään tekijään.
        </Paragraph>
      </Section>
    </Content>
  )
}

export default Page
